import type { Schemas } from "#shopware";

export const usePinterestTrackingEvents = () => {
	const { send } = usePinterestTracking();

	const checkout = (order: Schemas["Order"]) => {
		send("checkout", {
			value: order.amountTotal,
			order_id: order.id,
			currency: "EUR",
			order_quantity: 1,
			line_items:
				order.lineItems?.map((item: Schemas["OrderLineItem"]) => ({
					product_id: item.referencedId,
					product_name: item.label,
					product_price: item.totalPrice,
					product_quantity: item.quantity,
					product_brand: "Kippie"
				})) ?? []
		});
	};

	const addToCart = (product: Schemas["Product"], quantity: number = 1) => {
		send("addtocart", {
			value: product.calculatedPrice?.totalPrice,
			currency: "EUR",
			order_quantity: quantity,
			line_items: [
				{
					product_id: product.id,
					product_name: product?.translated?.name ?? "Kippie Product",
					product_price: product.calculatedPrice?.totalPrice,
					product_quantity: quantity,
					product_brand: "Kippie"
				}
			]
		});
	};

	const pageVisit = () => {
		send("pagevisit", {});
	};

	const signup = () => {
		send("signup", {});
		send("lead", { lead_type: "signup" });
	};

	const watchVideo = (videoTitle: string) => {
		send("watchvideo", { video_title: videoTitle });
	};

	return {
		checkout,
		addToCart,
		pageVisit,
		signup,
		watchVideo
	};
};
